import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Circle, useMapEvents } from 'react-leaflet';
import { API_BASE_URL } from '../config/config';

const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

const MapEventsComponent = ({ setCenter }) => {
    useMapEvents({
        click(e) {
            setCenter(e.latlng);
        }
    });

    return null;
};

const SendNotification = () => {
    const [catalogues, setCatalogues] = useState([]);
    const [offers, setOffers] = useState([]);
    const [selectedCatalogue, setSelectedCatalogue] = useState(null);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [notificationType, setNotificationType] = useState("");
    const [center, setCenter] = useState([31.6408, -8.0151]);
    const [radius, setRadius] = useState(1000); // Default radius in meters
    const [customTitle, setCustomTitle] = useState(''); // Custom title state
    const [customBody, setCustomBody] = useState(''); // Custom body state
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchCatalogues = async () => {
            try {
                const response = await apiClient.get('/catalogs');
                setCatalogues(response.data);
            } catch (error) {
                console.error('Error fetching catalogues:', error);
                setMessage('Failed to fetch catalogues');
            }
        };

        const fetchOffers = async () => {
            try {
                const response = await apiClient.get('/offers');
                setOffers(response.data);
            } catch (error) {
                console.error('Error fetching offers:', error);
                setMessage('Failed to fetch offers');
            }
        };

        fetchCatalogues();
        fetchOffers();
    }, []);

    const handleSendNotification = async () => {
        if ((!selectedCatalogue && !selectedOffer) || !notificationType) {
            setMessage('Please select a catalogue or offer and notification type.');
            return;
        }

        try {
            const data = {
                catalogId: selectedCatalogue,
                offerId: selectedOffer,
                notificationType,
                center: notificationType.endsWith('_specific') ? center : null,
                radius: notificationType.endsWith('_specific') ? radius : null,
                customTitle: customTitle || null, // Add custom title to the data
                customBody: customBody || null // Add custom body to the data
            };
            await apiClient.post('/send-notification', data);
            setMessage('Notifications sent successfully!');
        } catch (error) {
            console.error('Error sending notifications:', error);
            setMessage('Error sending notifications.');
        }
    };

    return (
        <div className="min-h-screen flex justify-center bg-gray-100">
            <div className="bg-white p-12 rounded-lg shadow-lg w-3/4">
                <h1 className="text-3xl font-bold mb-6 text-center">Send Notification</h1>
                <div className="mb-6">
                    <label className="block text-gray-700 text-xl">Select Notification Type:</label>
                    <select
                        className="w-full px-4 py-3 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 text-xl"
                        value={notificationType}
                        onChange={e => setNotificationType(e.target.value)}
                    >
                        <option value="">-- Select Notification Type --</option>
                        <option value="catalogue_all">Catalogue to all</option>
                        <option value="offer_all">Offer to all</option>
                        <option value="catalogue_specific">Catalogue to specific area</option>
                        <option value="offer_specific">Offer to specific area</option>
                    </select>
                </div>
                {notificationType.startsWith('catalogue') && (
                    <div className="mb-6">
                        <label className="block text-gray-700 text-xl">Select Catalogue:</label>
                        <select
                            className="w-full px-4 py-3 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 text-xl"
                            value={selectedCatalogue}
                            onChange={e => setSelectedCatalogue(e.target.value)}
                        >
                            <option value="">-- Select Catalogue --</option>
                            {catalogues.map(catalogue => (
                                <option key={catalogue.id} value={catalogue.id}>
                                    {catalogue.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {notificationType.startsWith('offer') && (
                    <div className="mb-6">
                        <label className="block text-gray-700 text-xl">Select Offer:</label>
                        <select
                            className="w-full px-4 py-3 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 text-xl"
                            value={selectedOffer}
                            onChange={e => setSelectedOffer(e.target.value)}
                        >
                            <option value="">-- Select Offer --</option>
                            {offers.map(offer => (
                                <option key={offer.id} value={offer.id}>
                                    {offer.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {notificationType.endsWith('_specific') && (
                    <>
                        <MapContainer center={center} zoom={13} style={{ height: '500px' }}>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <Circle center={center} radius={radius} />
                            <MapEventsComponent setCenter={setCenter} />
                        </MapContainer>
                        <div className="my-6">
                            <label className="block text-gray-700 text-xl">Radius (meters):</label>
                            <input
                                type="number"
                                value={radius}
                                onChange={e => setRadius(Number(e.target.value))}
                                className="w-full px-4 py-3 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 text-xl"
                            />
                        </div>
                    </>
                )}
                <div className="mb-6">
                    <label className="block text-gray-700 text-xl">Custom Title:</label>
                    <input
                        type="text"
                        value={customTitle}
                        onChange={e => setCustomTitle(e.target.value)}
                        className="w-full px-4 py-3 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 text-xl"
                        placeholder="Enter your custom title here..."
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-xl">Custom Body:</label>
                    <textarea
                        className="w-full px-4 py-3 mt-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 text-xl"
                        value={customBody}
                        onChange={e => setCustomBody(e.target.value)}
                        placeholder="Enter your custom body message here..."
                    />
                </div>
                <button
                    className="w-full bg-indigo-600 text-white py-3 rounded-md hover:bg-indigo-700 transition duration-300 text-xl"
                    onClick={handleSendNotification}
                >
                    Send Notification
                </button>
                {message && <p className="mt-6 text-center text-red-500 text-xl">{message}</p>}
            </div>
        </div>
    );
};

export default SendNotification;
