import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const deleteCategory = async (id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    try {
      const response = await apiClient.delete(`/category/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Failed to delete the category", error);
    }
  };
  export const fetchCategories = async () => {
    try {
      const response = await apiClient.get(`${API_BASE_URL}/category`);
   
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch categories", error);
    }
  };
 
 
  export const updateCategory = async (id, name, name_ar) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    try {
      const response = await apiClient.put(
        `/category/update/${id}`,
        { name, name_ar }, // Include Arabic name in the request payload
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (!response.data) {
        throw new Error("Failed to receive the updated category data");
      }
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw new Error(`Failed to update the category: ${error.message}`);
    }
  };
  
  export const createCategory = async (name, name_ar, createBy) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
  
    try {
      const response = await apiClient.post(
        "/category/create",
        { name, name_ar, createBy }, // Include Arabic name in the request payload
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.data) {
        throw new Error("Failed to receive the new category data");
      }
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Failed to create category", error);
    }
  };
  