import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchSitesWithStories = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
    try {
      const response = await apiClient.get(`${API_BASE_URL}/stories/active`, );
      
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch sites", error);
    }
  };

  export const addStory = async (storyData, file) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const formData = new FormData();
    formData.append("image", file); 
    formData.append("siteId", storyData.siteId); 
    formData.append("subcategoryId", storyData.subcategoryId);
    formData.append("createBy", storyData.createBy);

    try {
        const response = await apiClient.post("/stories/create", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,

              },
        });
        return response.data;
    } catch (error) {
        console.error("Failed to add story:", error.response ? error.response.data : error.message);
        throw new Error("Failed to add story", error);
    }
};
export const deleteStory = async (id) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('Authentication token not found');
  }
  try {
 
    const response = await apiClient.delete(`/stories/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw new Error('Failed to delete story');
  }
};