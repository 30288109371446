import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import DynamicTable from "../components/table/DynamicTable";
import {
  fetchSubCategory,
  deleteSubCategory as apiDeleteMagazin,
} from "../services/magazinService";
import ModelDelete from "../components/modals/ModelDelete";
import MagazinForm from "../components/magazin/MagazinForm";
import Modal from "../components/modals/Modal";
import { toast } from "react-toastify";

function Magazin() {
  const headers = [
    "ID",
    "Name",
    "CreatedBy",
    "Category",
    "Created At",
    "Updated At",
    "Action",
  ];
  const [magazin, setMagazin] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [magazinToDelete, setMagazinToDelete] = useState(null);
  const [selectedMagazin, setSelectedMagazin] = useState(null);

  const handleAddNew = () => {
    setSelectedMagazin(null);
    setModalOpen(true);
  };

  const handleEditSubCategory = (subcategoryId) => {
    const subcategory = magazin.find((cat) => cat.ID === subcategoryId);
    if (!subcategory) {
      console.error("Subcategory not found for ID:", subcategoryId);
      return;
    }
    setSelectedMagazin(subcategory);
    setModalOpen(true);
  };

  const handleDeleteMagazin = (id) => {
    setMagazinToDelete(id);
    setConfirmationModalOpen(true);
  };
  const loadMagazin = async () => {
    try {
      const data = await fetchSubCategory();
      setMagazin(
        data.map((subcat) => ({
          ID: subcat.id,
          Name: subcat.name,
          Name_Ar: subcat.name_ar,
          CreatedBy : subcat.createBy,
          Category: subcat.category.name,
          CategoryId : subcat.categoryId,
          "Created At": new Date(subcat.createdAt).toLocaleDateString(),
          "Updated At": new Date(subcat.updatedAt).toLocaleDateString(),
        }))
      );
    } catch (error) {
      console.error("Failed to load magazin:", error);
    }
  };

  useEffect(() => {
    
    loadMagazin();
  }, []);

  const confirmDeleteCategory = async () => {
    try {
      const result = await apiDeleteMagazin(magazinToDelete);
      if (result) {
        setMagazin(magazin.filter((cat) => cat.ID !== magazinToDelete));
        toast.success('Magazin deleted successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Failed to delete magazin:", error);
      toast.error('Failed to delete magazin: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setConfirmationModalOpen(false);
      setMagazinToDelete(null);
    }
  };

  const saveMagazin =async (subcategory) => {
    setModalOpen(false);

    try {
      await loadMagazin(); // Rechargez les catégories après la sauvegarde
      toast.success('Category saved successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error('Failed to save category: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div>
      <Breadcrumb currentPath="Manage Magazin" title="Magazin" />
      <DynamicTable
        title="Magazin"
        headers={headers}
        onAddNew={handleAddNew}
        onEdit={handleEditSubCategory}
        data={magazin}
        onDelete={handleDeleteMagazin}
        titlebtn="Magazin"
      />
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <MagazinForm
          onSave={saveMagazin}
          onClose={() => setModalOpen(false)}
          initialData={selectedMagazin}
        />
      </Modal>
      <ModelDelete
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDeleteCategory}
      />
    </div>
  );
}

export default Magazin;
