import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { fetchSitesWithStories, deleteStory } from "../services/storiesService";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ModelDelete from "../components/modals/ModelDelete";

export default function Stories() {
  const [sitesWithStories, setSitesWithStories] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [storyToDelete, setStoryToDelete] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const loadSitesWithStories = async () => {
      try {
        const data = await fetchSitesWithStories();
        setSitesWithStories(data);
      } catch (error) {
        console.error("Failed to fetch sites with stories:", error);
      }
    };
    loadSitesWithStories();
  }, []);

  const confirmDeleteStory = async () => {
    try {
      await deleteStory(storyToDelete);
          window.location.reload();

    } catch (error) {
      console.error("Failed to delete story:", error);
    } finally {
      setConfirmationModalOpen(false);
      setStoryToDelete(null);
    }
  };

  const handleDeleteStory = (id) => {
    setStoryToDelete(id);
    setConfirmationModalOpen(true);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const navigatToAddNewStory = () => {
    navigate("/app/stories/create");
  };

  return (
    <div>
      <Breadcrumb currentPath="Manage Stories" title="Stories" />
      <div className="w-full p-4 bg-white rounded-lg">
        <button
          onClick={navigatToAddNewStory}
          className="flex items-center justify-center bg-[#6457E4] hover:bg-[#6457E4] text-white font-medium py-2 px-4 rounded-lg"
        >
          <FaPlus className="mr-2" /> Add New Story
        </button>
      </div>
      <div className="w-full mt-6 mx-auto p-4 bg-white rounded-lg">
        {sitesWithStories.map((site) => (
          <div key={site.id} className="mb-4">
            <h2 className="text-lg font-semibold mb-2">{site.address}</h2>
            <ul className="flex flex-wrap">
              {site.stories.map((story) => (
                <li key={story.id} className="p-2 relative">
                  <img
                    src={story.imageUrl}
                    alt="Story"
                    className="w-24 h-24 rounded-full object-cover cursor-pointer hover:shadow-lg transition-shadow duration-300"
                    onClick={() => handleImageClick(story.imageUrl)}
                  />
                  <button
                    onClick={() => handleDeleteStory(story.id)}
                    className="absolute top-0 right-0 mt-1 mr-1 bg-red-500 hover:bg-red-700 text-white text-xs font-bold py-1 px-2 rounded-full focus:outline-none"
                  >
                    X
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {selectedImage && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-50"
          onClick={closeImage}
        >
          <img
            src={selectedImage}
            alt="Zoomed Story"
            className="max-w-3/4 max-h-3/4 p-4"
          />
        </div>
      )}
      <ModelDelete
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDeleteStory}
      />
    </div>
  );
}
