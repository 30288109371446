import React from 'react';

const PolicyPage = () => {
    return (
        <div className="min-h-screen flex justify-center bg-gray-100 p-12">
            <div className="bg-white p-12 rounded-lg shadow-lg w-full max-w-4xl">
                <h1 className="text-3xl font-bold mb-6 text-center">Privacy Policy For 'Katalo App'</h1>
                <div className="policy-content text-gray-700 text-lg">
                    <p className="mb-4">
                        <strong>Last updated: March 9, 2023</strong>
                    </p>
                    <p className="mb-4">
                        This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
                    </p>
                    <p className="mb-4">
                        We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
                    </p>
                    <p className="mb-4">
                        <strong>Interpretation and Definitions</strong>
                    </p>
                    <p className="mb-4">
                        <strong>Interpretation</strong>
                    </p>
                    <p className="mb-4">
                        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                    </p>
                    <p className="mb-4">
                        <strong>Definitions</strong>
                    </p>
                    <p className="mb-4">
                        For the purposes of this Privacy Policy:
                    </p>
                    <ul className="mb-4">
                        <li className="mb-2">
                            <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
                        </li>
                        <li className="mb-2">
                            <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.
                        </li>
                        <li className="mb-2">
                            <strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named Katalo App.
                        </li>
                        <li className="mb-2">
                            <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Mle.inc, Rabat-Sale-Kenitra, Kenitra.
                        </li>
                        <li className="mb-2">
                            <strong>Country</strong> refers to Morocco.
                        </li>
                        <li className="mb-2">
                            <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone, or a digital tablet.
                        </li>
                        <li className="mb-2">
                            <strong>Service</strong> refers to the Application.
                        </li>
                    </ul>
                    <p className="mb-4">
                        <strong>Collecting and Using Your Personal Data</strong>
                    </p>
                    <p className="mb-4">
                        <strong>Types of Data Collected</strong>
                    </p>
                    <p className="mb-4">
                        <strong>Personal Data</strong>
                    </p>
                    <p className="mb-4">
                        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
                    </p>
                    <ul className="mb-4">
                        <li className="mb-2">Email address</li>
                        <li className="mb-2">First name and last name</li>
                        <li className="mb-2">Phone number</li>
                        <li className="mb-2">Address, State, Province, ZIP/Postal code, City</li>
                    </ul>
                    <p className="mb-4">
                        <strong>Information from Third-Party Social Media Services</strong>
                    </p>
                    <p className="mb-4">
                        The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:
                    </p>
                    <ul className="mb-4">
                        <li className="mb-2">Google</li>
                        <li className="mb-2">Facebook</li>
                    </ul>
                    <p className="mb-4">
                        If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities, or Your contact list associated with that account.
                    </p>
                    <p className="mb-4">
                        You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.
                    </p>
                    <p className="mb-4">
                        <strong>Information Collected while Using the Application</strong>
                    </p>
                    <p className="mb-4">
                        While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:
                    </p>
                    <ul className="mb-4">
                        <li className="mb-2">Information regarding your location</li>
                    </ul>
                    <p className="mb-4">
                        We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server, or it may be simply stored on Your device.
                    </p>
                    <p className="mb-4">
                        You can enable or disable access to this information at any time, through Your Device settings.
                    </p>
                    <p className="mb-4">
                        <strong>Use of Your Personal Data</strong>
                    </p>
                    <p className="mb-4">
                        The Company may use Personal Data for the following purposes:
                    </p>
                    <ul className="mb-4">
                        <li className="mb-2">
                            To provide and maintain our Service, including to monitor the usage of our Service.
                        </li>
                        <li className="mb-2">
                            To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
                        </li>
                        <li className="mb-2">
                            For the performance of a contract: the development, compliance, and undertaking of the purchase contract for the products, items, or services You have purchased or of any other contract with Us through the Service.
                        </li>
                        <li className="mb-2">
                            To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including security updates, when necessary or reasonable for their implementation.
                        </li>
                        <li className="mb-2">
                            To provide You with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
                        </li>
                        <li className="mb-2">
                            To manage Your requests: To attend and manage Your requests to Us.
                        </li>
                        <li className="mb-2">
                            For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
                        </li>
                        <li className="mb-2">
                            For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our Service, products, services, marketing, and your experience.
                        </li>
                    </ul>
                    <p className="mb-4">
                        <strong>Security of Your Personal Data</strong>
                    </p>
                    <p className="mb-4">
                        The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
                    </p>
                    <p className="mb-4">
                        <strong>Your Rights under the GDPR</strong>
                    </p>
                    <p className="mb-4">
                        The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.
                    </p>
                    <p className="mb-4">
                        You have the right under this Privacy Policy, and by law if You are within the EU, to:
                    </p>
                    <ul className="mb-4">
                        <li className="mb-2">
                            Request access to Your Personal Data. The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section or by email (for deletion request). If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.
                        </li>
                        <li className="mb-2">
                            Request correction of the Personal Data that We hold about You. You have the right to have any incomplete or inaccurate information We hold about You corrected.
                        </li>
                        <li className="mb-2">
                            Request erasure of Your Personal Data. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.
                        </li>
                    </ul>
                    <p className="mb-4">
                        <strong>Contact Us</strong>
                    </p>
                    <p className="mb-4">
                        If you have any questions about this Privacy Policy, You can contact us:
                    </p>
                    <ul className="mb-4">
                        <li className="mb-2">By email: mlifeeasier@gmail.com</li>
                        <li className="mb-2">By phone number: +212 656-486866</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PolicyPage;
