import React, { useEffect, useState } from 'react'
import Breadcrumb from '../components/Breadcrumb'
import { deleteOffers, fetchOffer } from '../services/offersService';
import DynamicTable from '../components/table/DynamicTable';
import { useNavigate } from 'react-router-dom';
import ModelDelete from '../components/modals/ModelDelete';
import DetailsOfferModal from '../components/offers/DetailsOfferModal';

function Offer() {
  const [offers, setOffers] = useState([]);
  const [offerToDelete, setOfferToDelete] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleAddNew = () => {
    navigate("/app/offers/create");
  };
  const loadOffers = async () => {
    try {
      const data = await fetchOffer();
      setOffers(data.map(offer => ({
        ID: offer.id,
        name: offer.name,
        name_ar: offer.name_ar,
        CreatedBy: offer.createBy,
        date_debut:  new Date(offer.date_debut).toLocaleDateString(),
        date_fin:   new Date(offer.date_fin).toLocaleDateString(),
        'Created At': new Date(offer.createdAt).toLocaleDateString(),
        'Updated At': new Date(offer.updatedAt).toLocaleDateString(),
        products: offer.products || []
      })));
    } catch (error) {
      console.error('Failed to load product:', error);
    }
  };
  const handleDeleteOffers = (id) => {
    setOfferToDelete(id);
    setConfirmationModalOpen(true);
  };
  const confirmDeleteOffers = async () => {
    try {
      await deleteOffers(offerToDelete);
      window.location.reload();

    } catch (error) {
      console.error("Failed to delete catalog:", error);
    } finally {
      setConfirmationModalOpen(false);
      setOfferToDelete(null);
    }
  };



  const handleDetails = (id) => {
    const offer = offers.find(offer => offer.ID === id);
    setSelectedOffer(offer);
    setDetailsModalOpen(true);
  };
  useEffect(() => {
    loadOffers();
  }, []);
  const handleEditOffers = (id) => {
    navigate(`/app/offers/edite/${id}`);
  };
  return (
    <div>
        <Breadcrumb currentPath="Manage offer" title="Offers" />
        <DynamicTable
          title="Offer"
          onAddNew={handleAddNew}
          data={offers}
          headers={["ID", "name", "CreatedBy",  "date_debut",
            "date_fin",  "Created At", "Updated At", "Action"]}
          titlebtn="Offer"
         onDetails={handleDetails}
           onDelete={handleDeleteOffers}
          onEdit={handleEditOffers}
        />
        <ModelDelete
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDeleteOffers}
      />
         <DetailsOfferModal
        isOpen={detailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
        offer={selectedOffer}
      />
    </div>
  )
}

export default Offer