import React, { useState, useEffect } from 'react';
import Breadcrumb from '../components/Breadcrumb';
import DynamicTable from '../components/table/DynamicTable';
import { fetchProduct, deleteProduct } from "../services/productService";
import ModelDelete from '../components/modals/ModelDelete';
import ProductForm from '../components/product/ProductForm';
import Modal from '../components/modals/Modal';
import DetailsProductModal from '../components/product/DetailsProductModal';
import FiltreProduct from '../components/product/FiltreProduct';
import FilteredProductsTable from '../components/product/FilteredProductsTable';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

function Product() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productToDelete, setProductToDelete] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  
  const loadProducts = async () => {
    try {
      const data = await fetchProduct();
      setProducts(data.map(prod => ({
        ID: prod.id,
        Title: prod.title,
        title_ar: prod.title_ar,  // Arabic title
        CreatedBy: prod.createBy,
        Description: prod.description,
        description_ar: prod.description_ar,  // Arabic description
        Price: prod.price,
        OldPrice: prod.oldPrice,
        productCategoryId: prod.productCategoryId,  // Pass the ID
        siteId: prod.siteId,  // Pass the ID
        'Product Category': prod.categoryproduct.name,  // Still display the name for the table
        'Site': prod.site.address,  // Still display the address for the table
        'Created At': new Date(prod.createdAt).toLocaleDateString(),
        'Updated At': new Date(prod.updatedAt).toLocaleDateString(),
        Images: prod.images || []
      })));
    } catch (error) {
      console.error('Failed to load product:', error);
    }
  };
  useEffect(() => {
    loadProducts();
  }, []);


  const confirmDeleteProduct = async () => {
    try {
     await deleteProduct(productToDelete);
      window.location.reload();

    } catch (error) {
      console.error("Failed to delete product:", error);
    } finally {
      setConfirmationModalOpen(false);
      setProductToDelete(null);
    }
  };

  const handleDeleteProduct = (id) => {
    setProductToDelete(id);
    setConfirmationModalOpen(true);
  };

  const navigate = useNavigate();

  const handleAddNew = () => {
    navigate("/app/product/create");
    setModalOpen(true);
  };

  const saveProduct = (prod) => {
    if (selectedProduct) {
      setProducts(
        products.map((cat) => (cat.ID === prod.ID ? prod : cat))
      );
    } else {
      setProducts([...products, prod]);
    }
    setModalOpen(false);
  };


  const handleDetails = (id) => {
    const product = products.find(prd => prd.ID === id);
    setSelectedProduct(product);
    setDetailsModalOpen(true);
  };

  const resetFilteredProducts = () => {
    setFilteredProducts([]);
  };

  const handleEditProduct = (id) => {
    const product = products.find((site) => site.ID === id);
    if (!product) {
      console.error("Product not found for ID:", id);
      return;
    }
    setSelectedProduct(product);
    setModalOpen(true);
  };

  return (
    <div>
      <Breadcrumb currentPath="Manage Product" title="Product" />
      <FiltreProduct setFilteredProducts={setFilteredProducts} />
      {filteredProducts.length > 0 ? (
        <FilteredProductsTable products={filteredProducts} onReset={resetFilteredProducts} />
      ) : (
        <DynamicTable
          title="Product"
          onDelete={handleDeleteProduct}
          onAddNew={handleAddNew}
          data={products}
          headers={["ID", "Title", "CreatedBy", "Description", "Price", "OldPrice", "Product Category", "Site", "Created At", "Updated At", "Action"]}
          titlebtn="Product"
          onDetails={handleDetails}
          onEdit={handleEditProduct}
        />
      )}
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="bg-white p-6 mx-4 sm:mx-0 sm:w-full md:max-w-xl">
          <ProductForm
            onSave={saveProduct}
            onClose={() => setModalOpen(false)}
            initialData={selectedProduct}
          />
        </div>
      </Modal>
      <ModelDelete
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDeleteProduct}
      />
      <DetailsProductModal
        isOpen={detailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
        product={selectedProduct}
      />
    </div>
  );
}

export default Product;
