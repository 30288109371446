import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchSlider = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }

  try {
    const response = await apiClient.get('/slides', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
   
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch slider: " + error.message);
  }
};



export const deleteSlide = async (id) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    await apiClient.delete(`${API_BASE_URL}/slides/delete/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    throw new Error("Failed to delete slide", error);
  }
};
export const createSlide = async (formData) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }

  try {
    const response = await apiClient.post('/slides/create', formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data", // Ensure proper content type for file upload
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to create slide: " + error.message);
  }
};