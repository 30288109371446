import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});
export const fetchSuperAdmins = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    try {
      const response = await apiClient.get(`${API_BASE_URL}/admins/superadmins`,{
          headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      });
   
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch users", error);
    }
  };

  
export const deleteUser = async (id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    try {
      const response = await apiClient.delete(`/admins/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Failed to delete user", error);
    }
  };
  

  
  export const createUsers = async (formData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    try {
      const response = await apiClient.post("/admins/register", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      // Checking if the error response has data and a message
      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        console.error("Error creating user: ", error);
        throw error;  // Maintaining the original error object if no specific message is found
      }
    }
  };
