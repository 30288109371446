import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchOffer = async () => {
    try {
      const response = await apiClient.get(`${API_BASE_URL}/offers`);
     
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch offers", error);
    }
  };

  export const deleteOffers = async (id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    try {
      const response = await apiClient.delete(`/offers/test/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;

    } catch (error) {
      console.error("Error:", error);
      throw new Error("Failed to delete offers", error);
    }
  };
  
  export const fetchOfferById = async (id) => {
    try {
        const response = await apiClient.get(`/offers/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch offer with ID ${id}:`, error);
        throw error;
    }
};
  export const updateOffer = async (id, formData) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    try {
      const response = await apiClient.put(
        `/offers/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (!response.data) {
        throw new Error("Failed to receive the new category data");
      }
      return response.data;
    } catch (error) {
      console.error("Error updating magazin:", error);
      throw error;
    }
  };

  export const createOffer = async (formData) => {
    // Retrieve the token from local storage
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
  
    try {
      const response = await apiClient.post(
        "/offers/create",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.data) {
        throw new Error("Failed to receive the new offer data");
      }
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("Failed to create offer", error);
    }
  };