import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import DynamicTable from "../components/table/DynamicTable";
import {
  fetchProductCategory,
  deleteProductCategory,
} from "../services/categoryProduct";
import Modal from "../components/modals/Modal";
import ProductCategoryForm from "../components/product-category/ProductCategoryForm";
import ModelDelete from "../components/modals/ModelDelete";
import { toast } from "react-toastify";

function ProductCategory() {
  const [productcategories, setProductcategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [productCategoryToDelete, setProductCategoryToDelete] = useState(null);
  const [selectedProductCategory, setSelectedProductCategory] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const handleAddNew = () => {
    setSelectedProductCategory(null);

    setModalOpen(true);
  };

  const handleEditProductCategory = (prodcategoryId) => {
    const prodcategory = productcategories.find(
      (cat) => cat.ID === prodcategoryId
    );
    if (!prodcategory) {
      console.error("Subcategory not found for ID:", prodcategoryId);
      return;
    }
    setSelectedProductCategory(prodcategory);
    setModalOpen(true);
  };

  const handleDeleteProductCategory = (id) => {
    setProductCategoryToDelete(id);
    setConfirmationModalOpen(true);
  };
  const confirmDeleteProductCategory = async () => {
    try {
       await deleteProductCategory(productCategoryToDelete);
      window.location.reload();

    } catch (error) {
      console.error("Failed to delete category:", error);
      
    } finally {
      setConfirmationModalOpen(false);
      setProductCategoryToDelete(null);
    }}

  const saveProductCategory =async (productcategory) => {
    setModalOpen(false);

    try {
      await loadCategories(); 
      toast.success('Category saved successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error('Failed to save category: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const loadCategories = async () => {
    try {
      const data = await fetchProductCategory();
      setProductcategories(
        data.map((cat) => ({
          ID: cat.id,
          Image : cat.imageUrl,
          Name: cat.name,
          name_ar: cat.name_ar,
          CreatedBy : cat.createBy,

          "Created At": new Date(cat.createdAt).toLocaleDateString(),
          "Updated At": new Date(cat.updatedAt).toLocaleDateString(),
        }))
      );
    } catch (error) {
      console.error("Failed to load product categories:", error);
    }
  };
  useEffect(() => {
    

    loadCategories();
  }, []);

  return (
    <div>
      <Breadcrumb currentPath="Category-Product" title="Category of Product" />
      <DynamicTable
        title="Product Categories"
        onAddNew={handleAddNew}
        onDelete={handleDeleteProductCategory}
        onEdit={handleEditProductCategory}
        headers={["ID","Image", "Name","CreatedBy", "Created At", "Updated At", "Action"]}
        data={productcategories}
        titlebtn="Product - Category"
      />
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ProductCategoryForm
          onSave={saveProductCategory}
          onClose={() => setModalOpen(false)}
          initialData={selectedProductCategory}
        />
      </Modal>
      <ModelDelete
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDeleteProductCategory}
      />
    </div>
  );
}

export default ProductCategory;
