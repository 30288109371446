import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchProductCategory = async () => {
    try {
      const response = await apiClient.get(`${API_BASE_URL}/categories-product`);
    
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch product categories", error);
    }
  };
  


export const createProductCategory = async (name, name_ar, file, createBy) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  const formData = new FormData();
  formData.append("name", name);
  formData.append("name_ar", name_ar); // Add Arabic name
  formData.append("image", file);
  formData.append("createBy", createBy);

  try {
    const response = await apiClient.post(
      "/categories-product/create",
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.data) {
      throw new Error("Failed to receive the new category data");
    }
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to create category", error);
  }
};

export const updateProductCategory = async (id, name, name_ar, file) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  const formData = new FormData();
  formData.append("name", name);
  formData.append("name_ar", name_ar); // Add Arabic name
  formData.append("image", file);

  try {
    const response = await apiClient.put(
      `/categories-product/update/${id}`, 
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.data) {
      throw new Error("Failed to receive the new category data");
    }
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw new Error(
      `Failed to update the category: ${error.response ? error.response.data : error.message}`
    );
  }
};


  
export const deleteProductCategory = async (id) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    const response = await apiClient.delete(`/categories-product/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to delete the category", error);
  }
};

export const fetchProductCategoriesBySiteId = async (siteId) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const response = await apiClient.get(`/categories-product/sites/${siteId}/productcategories`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching product categories by site ID:", error.response ? error.response.data : error);
    throw new Error("Failed to fetch product categories by site ID", error);
  }
};