import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import DynamicTable from "../components/table/DynamicTable";

import { fetchSite, deleteSite } from "../services/siteService";
import SiteForm from "../components/sites/siteForm";
import Modal from "../components/modals/Modal";
import ModelDelete from "../components/modals/ModelDelete";
import DetailsModal from "../components/sites/DetailsModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Site() {
  const [sites, setSites] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleAddNew = () => {
    navigate("/app/site/create");
    setModalOpen(true);
  };
  const handleDeleteSite = (id) => {
    setSiteToDelete(id);
    setConfirmationModalOpen(true);
  };

  const saveSite = async(site) => {
    
    setModalOpen(false);
    try {
      await loadSites();
      toast.success('Site saved successfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.error('Failed to save Site: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  
  const handleDetails = (id) => {
    const site = sites.find((site) => site.ID === id);
    setSelectedSite(site);
    setDetailsModalOpen(true);
  };
  const handleEditSite = (id) => {
    const site = sites.find((site) => site.ID === id);
    if (!site) {
      console.error("site not found for ID:", id);
      return;
    }
    setSelectedSite({
      ...site,
      subCategoryId: site.subCategoryId  
  });
    setSelectedSite(site);
    setModalOpen(true);
  };
  const loadSites = async () => {
    try {
      const data = await fetchSite();
      setSites(
        data.map((site) => ({
          ID: site.id,
          name: site.name,
          name_ar: site.name_ar,
          Latitude: site.latitude,
          Longitude: site.longitude,
          Telephone: site.telephone,
          Address: site.address,
          Magazin: site.subCategory.name,
          subcategoryId:site.subCategory.id,
          workingHours: site.workingHours,
          CreatedBy : site.createBy,
          "Created At": new Date(site.createdAt).toLocaleDateString(),
          "Updated At": new Date(site.updatedAt).toLocaleDateString(),
          Images: site.images || [],
        }))
      );
    } catch (error) {
      console.error("Failed to load sites:", error);
    }
  };

  useEffect(() => {
   
    loadSites();
  }, []);
  const confirmDeleteSite = async () => {
    try {
      await deleteSite(siteToDelete);
      window.location.reload();

    } catch (error) {
      console.error("Failed to delete site:", error);
    } finally {
      setConfirmationModalOpen(false);
      setSiteToDelete(null);
    }
  };


  
  return (
    <div>
      <Breadcrumb currentPath="Manage Site" title="Site" />
      <DynamicTable
        title="Sites"
        onAddNew={handleAddNew}
        onDelete={handleDeleteSite}
        headers={[
          "ID",
          "Latitude",
          "Longitude",
          "Telephone",
          "Address",
          "Magazin",
          "CreatedBy",
          "Created At",
          "Updated At",
          "Action",
        ]}
        data={sites}
        onDetails={handleDetails}
        onEdit={handleEditSite}
        titlebtn="Site"
      />
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="bg-white  p-6 mx-4 sm:mx-0 sm:w-full md:max-w-xl">
          <SiteForm
            onSave={saveSite}
            onClose={() => setModalOpen(false)}
            initialData={selectedSite}
          />
        </div>
      </Modal>

      <ModelDelete
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDeleteSite}
      />
      <DetailsModal
        isOpen={detailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
        site={selectedSite}
      />
    </div>
  );
}

export default Site;
