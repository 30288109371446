import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumb";
import {
  createUsers,
  deleteUser,
  fetchSuperAdmins,
} from "../services/usersService";
import DynamicTable from "../components/table/DynamicTable";
import ModelDelete from "../components/modals/ModelDelete";
import Modal from "../components/modals/Modal";
import { toast } from "react-toastify";

function Users() {
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const [userToDelete, setUserToDelete] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleAddNew = () => {
    setModalOpen(true);
  };

  const [modalData, setModalData] = useState({
    username: "",
    password: "",
    role: "superadmin", 
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setModalData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await createUsers(modalData);
      if (result) {
        setModalOpen(false);
        setModalData({ username: "", password: "", role: "superadmin" }); 
        await loadSuperadmins();  
        toast.success('User added successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setErrorMessage(error.message);
      toast.error('Error adding user: ' + error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  

  const loadSuperadmins = async () => {
    try {
      const data = await fetchSuperAdmins();
      console.log(data);
      setUsers(
        data.map((cat) => ({
          ID: cat.id,
          Username: cat.username,
          Role: cat.role,
          "Created At": new Date(cat.createdAt).toLocaleDateString(),
          "Updated At": new Date(cat.updatedAt).toLocaleDateString(),
        }))
      );
    } catch (error) {
      console.error("Failed to load users:", error);
    }
  };

  useEffect(() => {
    loadSuperadmins();
  }, []);

  const handleDeleteUser = (id) => {
    console.log(id);
    setUserToDelete(id);
    setConfirmationModalOpen(true);
    
    // Toast optionnel pour indiquer que l'utilisateur est prêt à être supprimé
    toast.info('Ready to delete user. Please confirm.', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  
  const confirmDeleteUser = async () => {
    try {
      const result = await deleteUser(userToDelete);
      if (result) {
        setUsers(prevUsers => prevUsers.filter(user => user.ID !== userToDelete));
    
      } else {
        // Si aucune donnée n'est retournée, c'est peut-être une erreur
        throw new Error("No result returned from delete operation");
      }
    } catch (error) {
      console.error("Failed to delete user:", error);
    
    } finally {
      setConfirmationModalOpen(false);
      setUserToDelete(null);
    }
  };
  
  
  
  return (
    <div>
      <Breadcrumb currentPath="Manage users" title="Users" />
      <DynamicTable
        title="Users"
        headers={[
          "ID",
          "Username",
          "Role",
          "Created At",
          "Updated At",
          "Action",
        ]}
        data={users}
        titlebtn="User"
        onAddNew={handleAddNew}
        onDelete={handleDeleteUser}
      />
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <form
          className="bg-white p-6 mx-4 sm:mx-0 sm:w-full md:max-w-xl"
          onSubmit={handleFormSubmit}
        >
          <h2 className="text-lg font-semibold mb-4">Add New User</h2>
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              Username
            </label>
            <input
              type="text"
              name="username"
              id="username"
              value={modalData.username}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={modalData.password}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="role"
              className="block text-sm font-medium text-gray-700"
            >
              Role
            </label>
            <select
              name="role"
              id="role"
              value={modalData.role}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="admin">Admin</option>
              <option value="superadmin">Super Admin</option>
            </select>
          </div>

            {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}

          <div className="mt-4 flex justify-end">
            <button
              type="submit"
              className="py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Create User
            </button>
          </div>
        </form>
      </Modal>

      <ModelDelete
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={confirmDeleteUser}
      />
    </div>
  );
}

export default Users;
