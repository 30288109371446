import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchSubCategory = async () => {
  try {
    const response = await apiClient.get(`${API_BASE_URL}/subcategory`);
 
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch sub-categories", error);
  }
};
export const deleteSubCategory = async (id) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    const response = await apiClient.delete(`/subcategory/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to create category", error);
  }
};

export const createSubCategory = async (formData) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    const response = await apiClient.post("/subcategory/create", formData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
 
    return response.data;
  } catch (error) {
    console.error(
      "Error creating magazin:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateSubCategory = async (id, formData) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    const response = await apiClient.put(
      `/subcategory/update/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.data) {
      throw new Error("Failed to receive the new category data");
    }
    return response.data;
  } catch (error) {
    console.error("Error updating magazin:", error);
    throw error;
  }
};

export const fetchProductsBySubcategoryId = async (id) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    const response = await apiClient.get(
      `/subcategory/products/subcategory/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.data) {
      throw new Error("Failed to receive the products");
    }
    return response.data;
  } catch (error) {
    console.error("Error updating magazin:", error);
    throw error;
  }
};
