import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
/*  Site */
export const fetchSite = async () => {
  try {
    const response = await apiClient.get(`${API_BASE_URL}/sites`);
  
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch sites", error);
  }
};
export const createSite = async (siteData, imageFiles) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }

  const formData = new FormData();
  Object.keys(siteData).forEach(key => {
    if (key !== 'working_hours') {
      formData.append(key, siteData[key]);
    }
  });

  // Convert working hours object to JSON string and append it to formData
  if (siteData.working_hours) {
    formData.append('working_hours', JSON.stringify(siteData.working_hours));
  }

  Array.from(imageFiles).forEach(file => {
    formData.append("images", file); // Correct for multiple images
  });

  try {
    const response = await apiClient.post("/sites/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to create site:", error.response ? error.response.data : error);
    throw new Error("Failed to create site: " + error.message);
  }
};


export const deleteSite = async (id) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    const response = await apiClient.delete(`/sites/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to create category", error);
  }
};

export const updateSite = async (id, siteData, imageFiles) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  const formData = new FormData();
  Object.keys(siteData).forEach((key) => {
    formData.append(key, siteData[key]);
  });
  Array.from(imageFiles).forEach((file) => {
    formData.append("images", file);
  });

  try {
    const response = await apiClient.put(`/sites/update/${id}`, formData,
     {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Failed to update site:",
      error.response ? error.response.data : error
    );
    throw new Error("Failed to update site:", error);
  }
};
export const countSites = async () => {
  try {
      const response = await apiClient.get(`/sites/count`);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data.count;
  } catch (error) {
      console.error("Failed to fetch site count:", error);
      throw error;
  }
};