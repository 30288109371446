import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/layout/Sidebar';
import Navbar from '../components/layout/Navbar';
import Content from '../components/Content';

const Layout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`flex-grow transition-all duration-300  md:ml-60`}>
        <Navbar toggleSidebar={toggleSidebar} />
        <Content>
          <Outlet/>
        </Content>
      </div>
    </div>
  );
};

export default Layout;