import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const createCatalog = async (catalogData, imageFiles, areasData) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }

  // Create a function to handle the form submission for each site
  const createCatalogForSite = async (siteId) => {
    const formData = new FormData();

    // Append catalog data to FormData for the specific site
    for (const key in catalogData) {
      if (key === "siteIds") {
        formData.append("siteId", siteId); // Add the current site ID
      } else {
        formData.append(key, catalogData[key]);
      }
    }

    // Append image files to FormData
    Array.from(imageFiles).forEach((file) => {
      formData.append("images", file);
    });

    // Append areasData
    formData.append("areasData", JSON.stringify(areasData));

    try {
      const response = await apiClient.post("/catalogs/create", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        `Failed to create catalog for site ${siteId}:`,
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  // Iterate over each selected site and post the catalog data for each
  const promises = catalogData.siteIds.map((siteId) => createCatalogForSite(siteId));

  try {
    // Wait for all the catalog creation requests to complete
    const responses = await Promise.all(promises);
    return responses; // Return the array of responses for all sites
  } catch (error) {
    console.error("Failed to create catalog for one or more sites:", error.message);
    throw error;
  }
};


export const fetchCatalog = async () => {
  try {
    const response = await apiClient.get(`${API_BASE_URL}/catalogs`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch catalogs", error);
  }
};

export const fetchCatalogById = async (id) => {
  try {
    const response = await apiClient.get(`/catalogs/${id}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch catalog by ID", error);
  }
};

export const updateCatalog = async (id, catalogData, imageCatalogs, areasData) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }

  // Include imageCatalogs, areasData, and deletedImages in the payload
  const payload = {
    ...catalogData,
    imageCatalogs: imageCatalogs || [], // Ensure this is always an array
    areasData: areasData || [], // Ensure this is always an array
    deletedImages: catalogData.deletedImages || [], // Include the list of deleted image IDs
  };

  try {
    const response = await apiClient.put(`/catalogs/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Explicitly set the content type
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update catalog:", error.response ? error.response.data : error.message);
    throw error;
  }
};






export const deleteCatalog = async (id) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    const response = await apiClient.delete(`/catalogs/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to delete catalog", error);
  }
};

// Add fetchSubCategory and fetchProductsBySubcategoryId functions if they are needed in your components.
export const fetchSubCategory = async () => {
  try {
    const response = await apiClient.get(`${API_BASE_URL}/subcategories`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch subcategories", error);
  }
};

export const fetchProductsBySubcategoryId = async (subcategoryId) => {
  try {
    const response = await apiClient.get(`${API_BASE_URL}/products?subcategoryId=${subcategoryId}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch products by subcategory ID", error);
  }
};
