import React, { useState, useEffect } from 'react';
import { FaClipboardList } from 'react-icons/fa'; 
import Card from '../components/dashboard/Card';
import { CiBoxes } from "react-icons/ci";
import LocationSite from '../components/product/LocationSite';
import Breadcrumb from "../components/Breadcrumb";
import { FaMapMarkerAlt } from "react-icons/fa";
import { fetchProduct } from "../services/productService";
import {fetchCategories} from "../services/categoryService";
import { fetchSite } from "../services/siteService";
import {
  fetchSubCategory
} from "../services/magazinService";
import { BiStore } from 'react-icons/bi';
export default function Dashboard() {
  const [formData, setFormData] = useState({ siteId: "" });
  const [magazinCount, setMagazinCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const [siteCount, setSiteCount] = useState(0);
  const loadSites = async () => {
    try {
      const data = await fetchSite();
      if (data && Array.isArray(data)) {
        setSiteCount(data.length); 
      }
    } catch (error) {
      console.error("Failed to load sites:", error);
    }
  };
  const loadCategories = async () => {
    try {
      const data = await fetchCategories();
      if (data && Array.isArray(data)) {
        setCategoryCount(data.length);
      }
    } catch (error) {
      console.error("Failed to load sites:", error);
    }
  };
  const loadMagazins = async () => {
    try {
      const data = await fetchSubCategory();
      if (data && Array.isArray(data)) {
        setMagazinCount(data.length);
      }
    } catch (error) {
      console.error("Failed to load sites:", error);
    }
  }; 
  const loadProducts = async () => {
    try {
      const data = await fetchProduct();
      if (data && Array.isArray(data)) {
        setProductCount(data.length); 
      }
    } catch (error) {
      console.error("Failed to load sites:", error);
    }
  };
 
  useEffect(() => {
    loadSites();
    loadMagazins();
    loadProducts();
    loadCategories();
  }, []);
  const handleSiteSelect = (siteId) => {
    setFormData({ ...formData, siteId });
  };
  return (
    <div className="flex flex-col justify-around p-5 bg-gray-100">
      <Breadcrumb currentPath="Dashboard " title="Product" />

     <div className="flex flex-row  justify-around  ">
     <Card title="Total Magazin" mainStat={magazinCount} icon={<BiStore />} iconBgColor="bg-orange-200"/>
      <Card title="Total Product" mainStat={productCount} icon={<CiBoxes />} iconBgColor="bg-green-200"/>
      <Card title="Total Site" mainStat={siteCount} icon={<FaMapMarkerAlt />} iconBgColor="bg-blue-200"/>
      <Card title="Total Category " mainStat={categoryCount} icon={< FaClipboardList />} iconBgColor="bg-red-200"/>
      
     </div>
      
      <div className="mt-4">
      <LocationSite onSiteSelect={handleSiteSelect} />

    </div>
    </div>
  );
}
