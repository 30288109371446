import axios from "axios";
import { API_BASE_URL } from "../config/config";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const fetchProduct = async () => {
    try {
      const response = await apiClient.get(`${API_BASE_URL}/products`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch sites", error);
    }
  };

  
export const deleteProduct = async (id) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    const response = await apiClient.delete(`/products/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to delete the products");
    }

    const data = await response.json();
    console.log("Delete successful:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to delete products", error);
  }
};

export const createProduct = async (productData, imageFiles) => {
  // Retrieve the token from local storage
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  const formData = new FormData();
  Object.keys(productData).forEach(key => {
    formData.append(key, productData[key]);
  });
  Array.from(imageFiles).forEach(file => {
    formData.append('images', file); 
});
try {
  const response = await apiClient.post("products/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
  console.log('products created:', response.data);
  return response.data;
} catch (error) {
  console.error("Failed to create products:", error.response ? error.response.data : error);
  throw new Error("Failed to create products:", error);
}
}

export const fetchProductsBySiteId = async (siteId) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found");
  }
  try {
    const response = await apiClient.get(`/sites/${siteId}/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching products for site:", error.response ? error.response.data : error);
    throw new Error("Failed to fetch products for site", error);
  }
};
export const fetchProductsByCategoryId = async (categoryId) => {
  try {
      const response = await apiClient.get(`/products/category/${categoryId}/products`, {
          headers: {
              // Include your authorization token if needed
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
      });
      return response.data; 
  } catch (error) {
      console.error('Failed to fetch products:', error);
      throw new Error('Failed to fetch products');
  }
};

export const updateProduct = async (id, productData, imageFiles) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('Authentication token not found');
  }

  const formData = new FormData();
  Object.keys(productData).forEach(key => {
    formData.append(key, productData[key]);
  });

  // Append image files to FormData
  imageFiles.forEach(file => {
    if (file instanceof File) {
      formData.append('images', file);
    }
  });

  const response = await apiClient.put(`/products/update/${id}`, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};
