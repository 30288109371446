import React from 'react'
import Breadcrumb from '../components/Breadcrumb'
import SliderTable from '../components/slider/TableSlider'

function Slider() {
  return (
    <div>
        <Breadcrumb currentPath="Manage Slider" title="Slider" />
        <SliderTable/>

    </div>
  )
}

export default Slider